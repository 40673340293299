<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          label-width="140px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="中国海关编码" prop="hsCode">
            <el-input
              v-model="queryForm.hsCode"
              clearable
              placeholder="请输入"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="商品名称" prop="items">
            <el-input
              v-model="queryForm.items"
              clearable
              placeholder="请输入"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <el-button size="mini" type="primary" @click="addOrEdit">
          新增
        </el-button>
        <el-button
          :disabled="!queryForm.idList.length > 0"
          size="mini"
          type="danger"
          @click="deleteData"
        >
          删除
        </el-button>
        <uploadExcel down-url="海关编码字典导入模板.xlsx" :upload-aciton="bt" />
        <el-button
          :loading="loadingExPort"
          size="mini"
          type="primary"
          @click="exPort"
        >
          导出
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="showRegulatoryConditionsCodeTableAction"
        >
          监管条件代码表
        </el-button>
        <el-button size="mini" type="primary" @click="showCodeTableAction">
          检验检疫代码表
        </el-button>
        <el-button size="mini" type="primary" @click="openColumnSettings">
          设置列
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <div style="height: calc(100vh - 320px)">
      <vxe-table
        id="customsCodeManageTable"
        ref="tableRef"
        v-loading="listLoading"
        border
        :checkbox-config="{ trigger: 'row', range: true }"
        :custom-config="customConfig"
        :data="list"
        height="100%"
        :keep-source="true"
        :row-config="{ isHover: true, isCurrent: true, height: 35 }"
        :scroll-y="{ enabled: true }"
        show-overflow
        @checkbox-all="setSelectRows"
        @checkbox-change="setSelectRows"
      >
        <vxe-column
          align="center"
          field="selectOption"
          fixed="left"
          type="checkbox"
          width="55"
        />
        <vxe-column
          align="center"
          field="indexOption"
          fixed="left"
          title="序号"
          type="seq"
          width="70"
        >
          <template #default="{ seq }">
            {{ seq + (queryForm.pageNum - 1) * queryForm.pageSize }}
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          field="hsCode"
          min-width="120"
          title="中国海关编码"
        />
        <vxe-column
          align="center"
          field="category"
          min-width="140"
          title="大类"
        />
        <vxe-column
          align="center"
          field="chapter"
          min-width="140"
          title="章节"
        />
        <vxe-column
          align="center"
          field="items"
          min-width="200"
          title="商品名称"
        />
        <vxe-column
          align="center"
          field="description"
          min-width="200"
          title="商品描述"
        />
        <vxe-column
          align="center"
          field="declarationElementsCn"
          min-width="200"
          title="中国申报要素"
        />
        <vxe-column
          align="center"
          field="regulatoryCondition"
          min-width="100"
          title="监管条件"
        />
        <vxe-column
          align="center"
          field="generalRates"
          min-width="100"
          title="普通税率"
        />
        <vxe-column
          align="center"
          field="preferentialRates"
          min-width="100"
          title="优惠税率"
        />
        <vxe-column
          align="center"
          field="exitRates"
          min-width="200"
          title="出口税率"
        />
        <vxe-column
          align="center"
          field="consumeRates"
          min-width="200"
          title="消费税率"
        />
        <vxe-column
          align="center"
          field="vatRates"
          min-width="200"
          title="增值税率"
        />
        <vxe-column
          align="center"
          field="firstLegalUnit"
          min-width="200"
          title="第一法定单位"
        />
        <vxe-column
          align="center"
          field="secondLegalUnit"
          min-width="200"
          title="第二法定单位"
        />
        <vxe-column
          align="center"
          field="inspection"
          min-width="200"
          title="检验检疫"
        />
        <vxe-column
          align="center"
          field="createBy"
          min-width="200"
          title="创建人"
        />
        <vxe-column
          align="center"
          field="createTime"
          min-width="200"
          title="创建时间"
        />
        <vxe-column
          align="center"
          field="updateBy"
          min-width="200"
          title="修改人"
        />
        <vxe-column
          align="center"
          field="updateTime"
          min-width="200"
          title="修改时间"
        />
        <vxe-column
          align="center"
          field="dataSource"
          min-width="200"
          title="数据来源"
        />
        <vxe-column
          align="center"
          field="option"
          fixed="right"
          title="操作"
          width="60"
        >
          <template #default="{ row }">
            <el-button style="padding: 0" type="text" @click="addOrEdit(row)">
              编辑
            </el-button>
          </template>
        </vxe-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <table-edit
      ref="edit"
      :modulelist="queryList"
      :system-guid="queryForm.systemGuid"
    />
    <regulatory-conditions-code-table
      :open.sync="showRegulatoryConditionsCodeTable"
    />
    <code-table-for-inspection-and-quarantine :open.sync="showCodeTable" />
  </div>
</template>

<script>
  import {
    hsCodeDictDeleteApi,
    hsCodeDictExportApi,
    hsCodeDictImportDatasApi,
    hsCodeDictListApi,
  } from '@/api/cmsApi'
  import uploadExcel from '@/components/uploadExcel'
  import VabQueryFormLeftPanel from '@/vab/components/VabQueryForm/components/VabQueryFormLeftPanel.vue'
  import VabQueryFormTopPanel from '@/vab/components/VabQueryForm/components/VabQueryFormTopPanel.vue'
  import VabQueryForm from '@/vab/components/VabQueryForm/index.vue'
  import TableEdit from './components/addOrEdit'
  import CodeTableForInspectionAndQuarantine from './components/codeTableForInspectionAndQuarantine.vue'
  import RegulatoryConditionsCodeTable from './components/regulatoryConditionsCodeTable.vue'

  import tableConfigMixin from '@/mixins/tableConfigMixin'

  export default {
    name: 'CustomsCodeManage',
    components: {
      VabQueryFormTopPanel,
      VabQueryForm,
      VabQueryFormLeftPanel,
      TableEdit,
      RegulatoryConditionsCodeTable,
      CodeTableForInspectionAndQuarantine,
      uploadExcel,
    },
    mixins: [tableConfigMixin],
    data() {
      return {
        id: '',
        list: [],
        loadingExPort: false,
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        height: this.$baseTableHeight(3),
        queryForm: {
          hsCode: '',
          items: '',
          pageNum: 1,
          pageSize: 30,
          idList: [],
          sortName: '',
          sortRule: '',
        },
        showRegulatoryConditionsCodeTable: false,
        showCodeTable: false,
      }
    },

    created() {
      this.queryList()
    },
    methods: {
      indexMethod(index) {
        return (
          (this.queryForm.pageNum - 1) * this.queryForm.pageSize + index + 1
        )
      },
      tableSortChange(data) {
        this.queryForm.sortName = data.prop
        if (data.order == 'ascending') {
          this.queryForm.sortRule = 1
        } else if (data.order == 'descending') {
          this.queryForm.sortRule = 2
        } else {
          this.queryForm.sortName = ''
          this.queryForm.sortRule = ''
        }
        this.queryList()
      },
      //新增、编辑
      addOrEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      //重置
      reset(queryForm) {
        this.$refs[queryForm].resetFields()
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val
        this.queryList()
      },
      setSelectRows({ records }) {
        this.queryForm.idList = records.map((item) => item.id)
      },
      rowStyle({ row }) {
        var arr = this.queryForm.idList
        for (let i = 0; i < arr.length; i++) {
          if (row.id === arr[i]) {
            return 'rowStyle'
          }
        }
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      //查询
      async queryList() {
        this.listLoading = true
        const res = await hsCodeDictListApi(this.queryForm)
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false
      },
      deleteData() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定全部【删除】勾选数据吗？', null, async () => {
          const res = await hsCodeDictDeleteApi(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },
      jump(data) {
        this.$router.push({
          name: 'taskManage',
          params: {
            taskNo: data,
          },
        })
      },
      // 处理导出逻辑
      async handleExport(exportFunction, loadingState) {
        try {
          if (!this.queryForm.idList.length) {
            this.$message.warning('请勾选导出数据')
            return
          }
          this[loadingState] = true
          const res = await exportFunction(this.queryForm)
          this[loadingState] = false
          this.jump(res.data)
        } catch (error) {
          this[loadingState] = false
          this.$message.error('导出失败!')
        }
      },
      exPort() {
        this.handleExport(hsCodeDictExportApi, 'loadingExPort')
      },
      async bt(form) {
        try {
          const res = await hsCodeDictImportDatasApi(form)
          this.jump(res.data)
          this.queryList()
        } catch (error) {
          this.queryList()
        }
      },
      showRegulatoryConditionsCodeTableAction() {
        this.showRegulatoryConditionsCodeTable = true
      },
      showCodeTableAction() {
        this.showCodeTable = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }

  ::v-deep {
    .left-panel {
      margin: 0px !important;
    }

    .el-dialog__body {
      text-align: center;
    }

    .el-table {
      .el-table__body-wrapper::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }

      .el-table__body-wrapper::-webkit-scrollbar-thnmb {
        background-color: #ccc !important;
        border-radius: 5px;
      }

      // 此段可写可不写 用处：可使固定列的高度不盖住滚动条
      // /deep/.el-table__fiexd,
      // .el-table__fixed-right{
      //  height:calc(100% - 12px)!important;
      //}
      // 关键代码，解决错位问题 思路就是表格固定列显示滚动条后把表格顶上来
      .el-table-fixed .el-table__body {
        padding-bottom: 12px;
      }
    }
  }

  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: 99;
  }

  ::v-deep .rowStyle {
    background-color: #e8f7fd !important;
    color: #1890ff;
  }

  .columns-list {
    display: flex;
    flex-wrap: wrap;
  }

  .columns-list .el-checkbox {
    flex: 0 0 calc(20% - 10px); /* 每个复选框占1/5宽，减去间距 */
    margin: 5px;
    text-align: left; /* 首字符对齐 */
  }

  .columns-list .first-in-row {
    margin-left: 0; /* 第一个元素没有左边距 */
  }

  /* 确保全选复选框和按钮在同一行 */
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dialog-footer .el-checkbox {
    margin-right: auto; /* 将全选复选框推到左侧 */
  }
</style>
